/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react'

import { showErrorMessage } from '../../../actions/notification'
import makeRequest from '../../../libs/request'
import { CategoryComponent } from './CategoryComponent'
import NoDataComponent from './NoDataComponent'

const TabSystem = ({ idValue, listTab = [], handChange, ...props }) => {
  return (
    <div
      style={{ display: 'flex', gap: '8px' }}
      className='pl-16 pr-16 flex-wrap'
    >
      {listTab.map((i, index) => {
        return idValue === i?._id ? (
          <div
            key={index}
            className='row-center pt-6 pb-6 pl-12 pr-12'
            data-toggle='tooltip'
            data-placement='top'
            title={i.name}
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 500,
              background: '#338FEB',
              color: '#FFFFFF',
              height: '36px',
              borderRadius: '99px',
              cursor: 'pointer',
            }}
          >
            {i.name}
          </div>
        ) : (
          <div
            key={index}
            className='row-center pt-6 pb-6 pl-12 pr-12'
            data-toggle='tooltip'
            data-placement='top'
            title={i.name}
            onClick={() => {
              handChange(i)
            }}
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 500,
              background: '#F4F7FE',
              color: '#1B2559',
              height: '36px',
              borderRadius: '99px',
              cursor: 'pointer',
            }}
          >
            {i.name}
          </div>
        )
      })}
    </div>
  )
}

const SystemComponents = ({ value }) => {
  const [categoryList, setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getCompanyInfo = async () => {
    setIsLoading(true)
    makeRequest('get', `icpa/internal-reports/category/get-all/${value?._id}`)
      .then(({ data }) => {
        setIsLoading(false)
        if (data?.signal) {
          let itemList = data?.data
          if (itemList && itemList?.length > 0 && itemList[0]) {
            let list = itemList.map((i) => {
              return {
                ...i,
              }
            })
            setCategoryList([...list])
          } else {
            setCategoryList([])
          }
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (value?._id) {
      getCompanyInfo()
    }
  }, [value])

  return (
    <div>
      {categoryList?.length > 0 ? (
        categoryList.map((i, index) => {
          return (
            <CategoryComponent
              key={index}
              value={i}
              isInternal={true}
              defaultShow={index === 0}
            />
          )
        })
      ) : isLoading ? (
        <></>
      ) : (
        <NoDataComponent minHeight={'calc(100vh - 275px)'} />
      )}
    </div>
  )
}

export default function YourInternalDocumentedComponent(props) {
  const { value = {}, show = false, tab, id } = props
  const [systemList, setSystemList] = useState([])
  const [selectSystem, setSelectSystem] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const getCompanyInfo = async () => {
    setIsLoading(true)
    makeRequest('get', `icpa/internal-reports/system/get-all/${value?._id}`)
      .then(({ data }) => {
        setIsLoading(false)
        if (data?.signal) {
          let itemList = data?.data
          if (itemList && itemList?.length > 0 && itemList[0]) {
            let list = itemList.map((i) => {
              return {
                ...i,
              }
            })
            setSelectSystem(list[0])
            setSystemList([...list])
          } else {
            setSelectSystem(null)
            setSystemList([])
          }
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    if (value?._id && show) {
      getCompanyInfo()
    }
  }, [value, show, tab])

  return (
    <>
      {systemList?.length > 0 ? (
        <>
          {systemList?.length > 0 && (
            <div className='row pt-40 '>
              <TabSystem
                idValue={selectSystem?._id}
                listTab={systemList}
                handChange={(value) => {
                  setSelectSystem(value)
                }}
                {...props}
              />
            </div>
          )}

          <SystemComponents value={selectSystem} />
        </>
      ) : isLoading ? (
        <></>
      ) : (
        <NoDataComponent minHeight={'calc(100vh - 275px)'} />
      )}
    </>
  )
}
