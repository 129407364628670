import moment from 'moment'
import MomentTimezone from 'moment-timezone'
import React, { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import customEvents from 'highcharts-custom-events'

import { REMINDER_STATUS } from './CurrentYearComponent'
import { ButtonComponent } from './ButtonComponent'

customEvents(Highcharts)

const SummaryComponent = ({
  selectData,
  certification = {},
  changeSelect,
  companyId,
  valueDefault,
  selectDataCurrentYear,
}) => {
  const chartRef = useRef(null)
  const [seriesData, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [systemsTotal, setSystemsTotal] = useState(0)
  const [systemsActive, setSystemsActive] = useState(0)
  const [detailsList, setDetailsList] = useState([])

  const getValueReminders = (details = []) => {
    let seriesList = []
    let categoryList = []
    let pointsActive = []
    let pointsInActive = []
    if (details) {
      setDetailsList(details)
      details.map((item, index) => {
        categoryList.push(moment(item?.valid_from, 'DD/MM/YYYY').year())
      })
      let dataTotalReminders = []
      let dataTotalAcknowledged = []
      let dataTotalMissed = []
      let is_pass_external_audit = false
      details.map((item, index) => {
        is_pass_external_audit = item?.is_pass_external_audit === true
        if (is_pass_external_audit) {
          pointsActive.push(index)
          dataTotalReminders.push(item.totalReminders ?? 0)
          dataTotalAcknowledged.push(0)
          dataTotalMissed.push(0)
        } else {
          pointsInActive.push(index)
          dataTotalReminders.push(item.totalReminders ?? 0)
          dataTotalAcknowledged.push(item.totalAcknowledged ?? 0)
          dataTotalMissed.push(item.totalMissed ?? 0)
        }
      })
      seriesList.push(
        {
          name: `Total Reminder`,
          data: dataTotalReminders,
        },
        {
          name: `Acknowledged`,
          data: dataTotalAcknowledged,
        },
        {
          name: `Missed`,
          data: dataTotalMissed,
        }
      )
    }
    setCategories(categoryList)
    setSeries(seriesList)
    setTimeout(() => {
      pointUpdate(pointsActive, pointsInActive)
    }, [100])
  }

  const pointUpdate = (pointsActive, pointsInActive) => {
    let chart = chartRef.current.chart
    pointsActive.map((value) => {
      if (chart && chart.series && chart.series[0]) {
        let point = chart.series[0].points[value]
        if (point) {
          point.update({
            color: '#02C038',
          })
        }
      }
    })
    pointsInActive.map((value) => {
      if (chart && chart.series && chart.series[0]) {
        let point = chart.series[0].points[value]
        if (point) {
          point.update({
            color: '#0C60B4',
          })
        }
      }
    })
  }

  const getCertification = (certification) => {
    let details = []
    if (certification?.systems) {
      let isActive = certification?.systems.filter(
        (item) => item?.final_audit && item?.final_audit?.is_ready === true
      )
      setSystemsTotal(certification?.systems?.length)
      setSystemsActive(isActive?.length)
    } else {
      setSystemsTotal(0)
      setSystemsActive(0)
    }
    if (certification.details) {
      details = certification.details.map((i) => {
        let reminders = []
        let remindersAcknowledged = []
        let remindersMissed = []
        if (i.reminders) {
          reminders = i.reminders.filter(
            (i) =>
              i.status === REMINDER_STATUS.ACKNOWLEDGED ||
              i.status === REMINDER_STATUS.MISSED
          )
          remindersAcknowledged = i.reminders.filter(
            (i) => i.status === REMINDER_STATUS.ACKNOWLEDGED
          )
          remindersMissed = i.reminders.filter(
            (i) => i.status === REMINDER_STATUS.MISSED
          )
        }
        return {
          ...i,
          reminders: reminders,
          totalReminders: i?.reminders ? i.reminders?.length : 0,
          totalAcknowledged: remindersAcknowledged
            ? remindersAcknowledged?.length
            : 0,
          totalMissed: remindersMissed ? remindersMissed?.length : 0,
        }
      })
    }
    getValueReminders(details)
  }

  useEffect(() => {
    if (certification) {
      getCertification(certification)
    }
  }, [certification])

  let state = {
    options: {
      series: seriesData,
      chart: {
        type: 'column',
        alignTicks: false,
        height: 550,
        marginTop: 50,
        marginLeft: 0,
        marginRight: 0,
        borderRadius: 8,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      colors: [
        '#0C60B4',
        '#FF8800',
        '#FF443C',
        '#02C038',
        '#FFCA41',
        '#A1DC67',
        '#29B9E6',
        '#338FEB',
        '#003AA5',
        '#9747FF',
        '#FF0492',
      ],
      tooltip: {
        useHTML: true,
        headerFormat: '<table>',
        footerFormat: '</table>',
        formatter: function() {
          let selectDetails = detailsList.find((i) => {
            let year = moment(i?.valid_from, 'DD/MM/YYYY').year()
            return year.toString() === this.x.toString()
          })
          let is_pass_external_audit =
            selectDetails?.is_pass_external_audit === true
          const seriesName = is_pass_external_audit
            ? 'Total Reminder'
            : this.series.name === 'Acknowledged'
            ? 'Acknowledged Reminder'
            : this.series.name === 'Missed'
            ? 'Missed Reminder'
            : this.series.name

          return `<b>${seriesName}</b>: ${this.point.y}`
        },
      },
      xAxis: {
        categories: categories,
        gridLineWidth: 0,
        lineColor: '#C0C7D8',
        labels: {
          events: {
            click: function(e) {
              let isYear = e?.target?.id && e.target.id.slice(0, 6) === 'value_'
              if (isYear) {
                let year = e.target.id.slice(6)
                changeSelect(year)
              }
            },
          },
          useHTML: true,
          formatter: function() {
            let selectDetails = detailsList.find((i) => {
              let year = moment(i?.valid_from, 'DD/MM/YYYY').year()
              return year.toString() === this.value.toString()
            })
            let is_pass_external_audit = selectDetails?.is_pass_external_audit
            return `
							<div>
								<div
									style="
										display: flex;
										align-items: center;
										justify-content: center;
										width: 100%;
										padding-bottom: 8px;
									"
								>
									<span style="font-size: 16px; font-weight: 500; color: #1F263E; font-family: 'Poppins';" id="value_${
                    this.value
                  }">
										${this.value}
									</span>
								</div>
								<div>
									<div
										style="
											font-size: 14px;
											font-weight: 500;
											color: ${is_pass_external_audit ? '#FFFFFF' : '#1F263E'};
											background: ${is_pass_external_audit ? '#02C038' : '#F4F7FE'};
											padding: 6px 12px;
											border: ${is_pass_external_audit ? '1px solid #218A11' : '1px solid #1F263E'};
											border-radius: 6px;
											font-family: 'Poppins';
											min-weight: 161px;
										"
										id="${this.value}"
									>
										External Audit: Pass
									</div>
								</div>
							</div>
						`
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        series: {
          borderRadius: `8px`,
        },
      },
      legend: {
        align: 'left',
        margin: 32,
        itemStyle: {
          color: '#1F263E',
          fontSize: '14px',
          fontFamily: 'Poppins',
          align: 'center',
        },
        events: {
          itemClick: function() {
            return false
          },
        },
      },
    },
  }

  const width = categories?.length > 2 ? categories?.length * 190 : '100%'

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '-35px',
          right: '0px',
        }}
      >
        <div className='rowy-center-end'>
          {certification?.no_of_years_per_cycle && (
            <div
              style={{
                background: '#F4F7FE',
                border: '1px solid #1F263E',
                padding: '6px 12px',
                borderRadius: '6px',
              }}
              className='f-medium_20 mr-10'
            >
              {certification?.no_of_years_per_cycle} year
              {certification?.no_of_years_per_cycle > 1 && 's'} per cycle
            </div>
          )}
          {selectData?.valid_from && (
            <div
              style={{
                background: '#F4F7FE',
                border: '1px solid #1F263E',
                padding: '6px 12px',
                borderRadius: '6px',
              }}
              className='f-medium_20'
            >
              Start Date: {selectData?.valid_from}
            </div>
          )}
        </div>
        <div className='rowy-center-end mt-16'>
          <ButtonComponent
            active={selectDataCurrentYear?.final_audit?.is_ready}
            tooltipComponent={() => {
              let time = ''
              if (
                selectDataCurrentYear?.final_audit?.is_ready &&
                selectDataCurrentYear?.final_audit?.time
              ) {
                time = MomentTimezone.tz(
                  selectDataCurrentYear?.final_audit?.time,
                  'HH:mm:ss DD/MM/YYYY',
                  'Asia/Singapore'
                ).format('DD/MM/YYYY HH:mm:ss')
                return (
                  <div className='f-regular_20'>
                    Ready for final audit on {time}
                  </div>
                )
              }
              return (
                <div className='f-regular_20'>
                  Record is not yet ready for audit
                </div>
              )
            }}
          />
        </div>
        <div className='rowy-center-end mt-16'>
          <ButtonComponent
            title='System'
            active={systemsTotal === systemsActive && systemsActive}
            tooltipComponent={() => {
              let time = ''
              if (systemsTotal === systemsActive && systemsActive) {
                const latestData = certification?.systems.reduce(
                  (latest, current) => {
                    return MomentTimezone.tz(
                      latest?.final_audit?.time,
                      'HH:mm:ss DD/MM/YYYY',
                      'Asia/Singapore'
                    ) >
                      MomentTimezone.tz(
                        current?.final_audit?.time,
                        'HH:mm:ss DD/MM/YYYY',
                        'Asia/Singapore'
                      )
                      ? latest
                      : current
                  }
                )
                time = MomentTimezone.tz(
                  latestData?.final_audit?.time,
                  'HH:mm:ss DD/MM/YYYY',
                  'Asia/Singapore'
                ).format('DD/MM/YYYY HH:mm:ss')
                return (
                  <div className='f-regular_20'>
                    Ready for audit on {time} ({systemsActive}/{systemsTotal})
                  </div>
                )
              }
              return (
                <div className='f-regular_20'>
                  System is not yet ready for audit ({systemsActive}/
                  {systemsTotal})
                </div>
              )
            }}
          />
        </div>
      </div>
      <div
        className='scrollbar-custom '
        style={{
          minWidth: '100%',
          overflow: 'hidden',
          overflowX: 'auto',
          marginLeft: '-24px',
          marginRight: '-24px',
        }}
      >
        <div
          className='high-charts-row'
          style={{
            minWidth: width,
            overflow: 'hidden',
            overflowX: 'auto',
          }}
        >
          <HighchartsReact
            ref={chartRef}
            id='chart'
            highcharts={Highcharts}
            options={state.options}
            type='bar'
          />
        </div>
      </div>
    </>
  )
}

export default SummaryComponent
