import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

export const ButtonComponent = ({
	active = false,
	title = 'Record',
	tooltipComponent,
}) => {
	const [isActive, setIsActive] = useState(false)

	useEffect(() => {
		setIsActive(active)
	}, [active])

	return (
		<div
			style={{
				zIndex: 9,
			}}
		>
			<Tooltip
				placement='bottomLeft'
				title={tooltipComponent}
				color={'#ffffff'}
				overlayStyle={{ minWidth: '325px' }}
			>
				<div
					style={{
						background: isActive ? '#04AE34' : '#F4F7FE',
						border: isActive ? '1px solid #218A11' : '1px solid #1F263E',
						padding: '6px 12px',
						borderRadius: '6px',
						color: isActive ? '#FFFFFF' : '#1F263E',
						minWidth: '79px',
					}}
					className='f-medium_20'
				>
					{title}
				</div>
			</Tooltip>
		</div>
	)
}
